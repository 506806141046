import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import LogoSprite from "../images/sprite.svg"
import { useStaticQuery, graphql } from "gatsby"

let links = [
  {
    name: "Products",
    link: "/products",
  },
  {
    name: "Services",
    link: "/services",
  },
  {
    name: "Industries",
    link: "/industries",
  },
  {
    name: "About Us",
    link: "/about",
    sub: [
      {
        name: "Our Partners",
        link: "/partners",
      },
    ],
  },
  {
    name: "Contact",
    link: "/contact",
  },
]

const Header = ({ siteTitle, altNav }) => {
  const [openMenu, setOpenMenu] = React.useState(false)

  const data = useStaticQuery(graphql`
    query {
      allStrapiIndustries {
        edges {
          node {
            slug
            Title
          }
        }
      }
      allStrapiProduct {
        edges {
          node {
            slug
            Name
          }
        }
      }
    }
  `)

  // const products = []
  // for (let i = 0; i < data.allStrapiProduct.edges.length; i++) {
  //   const element = data.allStrapiProduct.edges[i]
  //   products.push({
  //     name: element.node.Name,
  //     link: `/products/${element.node.slug}`,
  //   })
  // }

  // const productIndex = links.findIndex(x => x.name === "Products")
  // links[productIndex].sub = products

  const industries = []
  for (let i = 0; i < data.allStrapiIndustries.edges.length; i++) {
    const element = data.allStrapiIndustries.edges[i]
    industries.push({
      name: element.node.Title,
      link: `/industries/${element.node.slug}`,
    })
  }
  const industryIndex = links.findIndex(x => x.name === "Industries")
  links[industryIndex].sub = industries

  const logo = altNav ? "#nextier-colored" : "#nextier-reversed"
  return (
    <header className="nextier-header">
      <div className="nextier-header-container">
        <div className="container">
          <nav className="main-nav">
            <Link
              to="/"
              aria-label="Nextier Plastics"
              title="Nextier Plastics"
              className="main-nav__logo"
            >
              <svg>
                <use href={LogoSprite + logo}></use>
              </svg>
            </Link>
            <ul
              id="navbar"
              className={`main-nav__navigation ${altNav ? "nav-reverse" : ""} ${
                openMenu ? "open" : ""
              }`}
            >
              {links.map(item => (
                <li className="hoverable">
                  <Link
                    to={item.link}
                    aria-label={item.name}
                    title={item.name}
                    className="main-nav__navigation-link"
                  >
                    {item.name}
                  </Link>
                  {item.sub && (
                    <div className="mega-menu">
                      <ul className="sub-menu">
                        {item.sub.map(item => (
                          <li>
                            <Link
                              to={item.link}
                              aria-label={item.name}
                              title={item.name}
                              className="sub-menu__link"
                            >
                              <span>{item.name}</span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </li>
              ))}
            </ul>
            {/* <a
              href="/"
              className="customer-portal-link"
              aria-label="Customer Portal"
              title="Customer Portal"
            >
              Customer Portal
            </a> */}
            {/* <!-- Mobile menu --> */}
            <div id="navbar-btn" className="mobile-menu-btn">
              <button
                onClick={() => setOpenMenu(!openMenu)}
                aria-label="Open Menu"
                title="Open Menu"
              >
                <svg viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                  ></path>
                  <path
                    fill="currentColor"
                    d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                  ></path>
                  <path
                    fill="currentColor"
                    d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                  ></path>
                </svg>
              </button>
            </div>
          </nav>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
